<template>
  <div class="live-wrapper">
    <Slide />
    <div class="live-conetnt">
      <Screen :jumpObject="jumpObject" :placeholder="'搜索直播房间名'" @getList="getList" />
      <div class="list-player">
        <watch-item
          v-if="Boolean(list.length) && getFnCodeList && getFnCodeList.includes('LIVE_LOOK')"
          :list="list"
          :isShow="false"
          @toLive="toLive"
        />
        <a-empty v-else>
          <template #description>
            <p>暂无直播</p>
          </template>
        </a-empty>
      </div>
      <Pagination v-if="getFnCodeList && getFnCodeList.includes('LIVE_LOOK')" :params="params" :total="total" />
    </div>

    <!-- 强制关闭 -->
    <revision-modal :title="'即将进入加密直播间，请输入直播间密码'" class="password-box">
      <template v-slot:content>
        <!-- 验证密码 -->
        <a-input v-model:value="passwordCheckValue.password" />
      </template>
      <template v-slot:footer>
        <a-button type="primary" @click="handleCancel" class="button-modal" ghost>取消</a-button>
        <a-button type="primary" @click="ok" class="button-modal">确认</a-button>
      </template>
    </revision-modal>
  </div>
</template>

<script>
import WatchItem from '@/components/WatchItem.vue';
import Pagination from '@/components/Pagination.vue';
import Screen from '@/components/Screen.vue';
import api from '@/services';
import { useRouter } from 'vue-router';
import Slide from '@/components/Slide.vue';
import { getLabelList } from '@/store/publicFunction.js';
import { provide, ref, onMounted, watch, computed } from 'vue';
import { setIsType } from '@/store/sharedData.js';
import { message } from 'ant-design-vue';
import { FormatTime } from '@/utils/tool';
import RevisionModal from '@/components/RevisionModal.vue';
import { fieldName, live_attribute, typeText } from '@/store/fieldConfiguration';
export default {
  components: {
    WatchItem,
    Screen,
    Pagination,
    RevisionModal,
    Slide
  },

  setup() {
    const getFnCodeList = computed(() => {
      return JSON.parse(localStorage.getItem('fnCodeList'));
    });
    const router = useRouter();
    setIsType(1);
    // 获取标签筛选列表
    const labelList = ref(null);
    provide('labelList', labelList);
    const setLabelList = async () => {
      labelList.value = await getLabelList('getLiveLabelList');
    };
    setLabelList();

    const params = ref({
      startOnlineTime: '',
      endOnlineTime: '',
      liveLabelList: [],
      roomUserIds: [],
      roomTitle: '',
      curPage: 1,
      pageSize: 10,
      status: 1,
      sort: '',
    });
    const total = ref(0);
    const list = ref([]);
    provide('params', params);
    const getList = async () => {
      try {
        if (params.value.startOnlineTime) {
          params.value.startOnlineTime = `${FormatTime(params.value.startOnlineTime)} 00:00:00`;
        }
        if (params.value.endOnlineTime) {
          params.value.endOnlineTime = `${FormatTime(params.value.endOnlineTime)} 23:59:59`;
        }
        const { data } = await api.getLiveList(params.value);
        total.value = data?.total || 0;
        list.value =
          data?.records?.map(item => {
            item.type = typeText[item.type];
            let paramsJson = JSON.stringify(item);
            live_attribute.forEach((oldKey, index) => {
              const reg = oldKey;
              paramsJson = paramsJson.replace(reg, fieldName[index]);
            });
            return JSON.parse(paramsJson);
          }) || [];
      } catch (error) {
        console.error(error);
      }
    };
    const visible = ref(false);
    provide('visible', visible);
    const passwordCheckValue = ref({
      password: '',
      roomId: ''
    });

    const query = ref({
      pullFlow: '',
      id: '',
      roomUserId: '',
      //  1 无人机直播  2 资源直播  3 普通直播
      liveType: 3
    });
    const toLive = data => {
      query.value.pullFlow = data.videoResourcesVO.pullAddressFlv;
      // query.value.pullFlow = data.videoResourcesVO.pullAddressHls;
      query.value.id = data.id;
      query.value.roomUserId = data.roomUserId;
      query.value.liveType = data.liveType
      if (!data.password) {
        router.push({
          path: '/live-broadcast-watch',
          query: { ...query.value }
        });
        return false;
      }
      visible.value = true;
      passwordCheckValue.value.roomId = data.id;
    };
    const ok = async () => {
      const { data } = await api.passwordCheck(passwordCheckValue.value);
      if (data) {
        router.push({
          path: '/live-broadcast-watch',
          query: { ...query.value }
        });
      } else {
        message.error('密码错误');
      }
    };
    // 弹窗取消
    const handleCancel = () => {
      visible.value = false;
    };

    watch(visible, () => {
      passwordCheckValue.value.password = '';
    });

    onMounted(() => {
      getList();
    });
    return {
      list,
      getList,
      params,
      total,
      jumpObject: {
        name: '发起直播',
        pathName: 'configuration'
      },
      handleCancel,
      ok,
      toLive,
      passwordCheckValue,
      getFnCodeList
    };
  }
};
</script>

<style lang="less" scoped>
@import '../../assets/less/public.less';
.live-wrapper {
  .live-video();

  .password-box {
    ::v-deep(.captcha-modal) {
      .ant-modal-content {
        padding: 0;
        .ant-modal-header {
          padding: 58px 0 20px;
          .ant-modal-title {
            font-size: 14px;
            line-height: 22px;
            width: 252px;
            margin: 0 auto;
          }
        }
        .ant-modal-body {
          background-color: #fff;
          padding: 0;
          text-align: center;
          div {
            margin-bottom: 20px;
          }
          input {
            height: 40px;
            border-radius: 5px;
            width: 168px;
          }
        }
        .ant-modal-footer {
          margin: 40px 0 30px;
        }
      }
    }
  }
}
</style>
